import React from 'react';
import { AlignedMarkdown, AlignedMarkdownProps } from '../components/atoms/aligned-markdown';
import { Section } from '../components/molecules/section';
import { SectionContentWithImage, SectionContentWithImageProps } from '../components/molecules/section-content-image';

export type GetInvolvedProps = {
  introduction?: AlignedMarkdownProps;
  sections?: Array<SectionContentWithImageProps>;
};

const getBackgroundColor = (i: number) => (i % 2 === 0 ? 'gray' : 'none');

export const GetInvolvedTemplate: React.FC<GetInvolvedProps> = props => {
  return (
    <>
      {props.introduction && (
        <Section marginPreset="all">
          {/* TODO Change maxWidth to new system */}
          <AlignedMarkdown {...props.introduction} maxWidth="body_text_xl" />
        </Section>
      )}

      {props.sections?.map((section, i) => (
        <Section key={section.title} marginPreset="none">
          <SectionContentWithImage {...section} backgroundColor={getBackgroundColor(i)} />
        </Section>
      ))}
    </>
  );
};
