import { SectionContentWithImageFragment } from '../../graphql-types';
import { AvailableLocales } from '../locale';
import { SectionContentWithImageProps } from '../components/molecules/section-content-image';
import { mapFragmentToImageProps } from './image.mapper';
import { mapFragmentToCtaProps } from './cta.mapper';
import { safeApply } from './utils';

export function mapFragmentToSectionContentWithImageProps(
  fragment: SectionContentWithImageFragment,
  locale: AvailableLocales
): SectionContentWithImageProps {
  return {
    image: mapFragmentToImageProps(fragment.media, locale, fragment.media_mobile),
    sectionTitle: fragment.section_title ?? '',
    title: fragment.title ?? '',
    text: fragment.text ?? '',
    cta: safeApply(value => mapFragmentToCtaProps(value), fragment.link),
    contentOrder: fragment.order ?? 'image_text',
  };
}
