import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useLocalizedSlugs } from '../hooks/useLocalizedSlugs';
import { parseLocale } from '../locale';
import { GetInvolvedQuery } from '../../graphql-types';
import { GetInvolvedTemplate } from '../templates/get-involved.template';
import { mapFragmentToAlignedMarkdownProps } from '../data-mappers/markdown.mapper';
import { mapFragmentToSectionContentWithImageProps } from '../data-mappers/get-involved.mapper';
import { safeApply, safeMap } from '../data-mappers/utils';

import SEO from '../components/seo';
import { useBreadcrumbs } from '../hooks/useBreadcrumbs';

export const query = graphql`
  query GetInvolved($language: String) {
    cms {
      getInvolved(locale: $language) {
        title
        introduction {
          ...AlignedMarkdown
        }
        sections {
          ...SectionContentWithImage
        }
        metas {
          description
        }
      }
    }
  }
`;

const GetInvolved: React.FC<{ data: GetInvolvedQuery }> = ({ data }) => {
  const { cms } = data;

  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  const intro = safeApply(intro => mapFragmentToAlignedMarkdownProps(intro), cms?.getInvolved?.introduction);
  const sections = safeMap(
    section => mapFragmentToSectionContentWithImageProps(section, locale),
    cms.getInvolved?.sections
  );

  useBreadcrumbs({
    pageTitle: cms.getInvolved?.title ?? '',
    contentCategory: intl.formatMessage({ id: 'kering_foundation' }),
  });

  useLocalizedSlugs({
    slugs: [
      { locale: 'en', slug: 'get-involved' },
      { locale: 'fr', slug: 'engagez-vous' },
    ],
  });

  return (
    <>
      <SEO lang={locale} title={cms.getInvolved?.title ?? ''} description={cms.getInvolved?.metas?.description ?? ''} />
      <GetInvolvedTemplate introduction={intro} sections={sections} />
    </>
  );
};

export default GetInvolved;
