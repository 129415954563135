import React from 'react';
import { graphql } from 'gatsby';

import { Image, ImageProps } from '../atoms/image';
import styled from '@emotion/styled';
import { CTA, CtaProps } from './cta';
import { Heading } from '../atoms/heading';
import { AlignedMarkdown } from '../atoms/aligned-markdown';

export type SectionContentWithImageProps = {
  image?: ImageProps;
  sectionTitle?: string;
  title?: string;
  text?: string;
  cta?: CtaProps;
  contentOrder?: 'text_image' | 'image_text';
  backgroundColor?: 'none' | 'gray';
};

const ColorWrapper = styled.div<Pick<SectionContentWithImageProps, 'backgroundColor'>>`
  ${({ backgroundColor, theme }) =>
    backgroundColor === 'gray' && `background: ${theme.color.palette['gray-lighten-3']};`}
`;

const Wrapper = styled.div<Pick<SectionContentWithImageProps, 'contentOrder'>>`
  display: flex;
  flex-direction: ${({ contentOrder }) => (contentOrder === 'text_image' ? 'row' : 'row-reverse')};
  justify-content: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: column-reverse;
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 50px 25px;
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    text-align: left;
    margin: 50px 100px;
  }
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 22px;
  padding-top: 70px;
`;

const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  min-width: 50%;
`;

export const SectionContentWithImage: React.FC<SectionContentWithImageProps> = props => {
  return (
    <ColorWrapper backgroundColor={props.backgroundColor}>
      <SectionTitleWrapper>
        <Heading level="h2" text={props.sectionTitle} />
      </SectionTitleWrapper>
      <Wrapper contentOrder={props.contentOrder}>
        <TextContent>
          {props.title && <Heading level="h3" text={props.title} />}
          {props.text && <AlignedMarkdown markdown={props.text} align="left" />}
          {props.cta && <CTA css={{ flex: '0 0 auto' }} {...props.cta} />}
        </TextContent>
        <ImageWrapper>
          <Image css={{ width: '100%', height: '100%' }} {...props.image} />
        </ImageWrapper>
      </Wrapper>
    </ColorWrapper>
  );
};

SectionContentWithImage.defaultProps = {
  contentOrder: 'text_image',
  backgroundColor: 'none',
};

export const sectionContentWithImageFragment = graphql`
  fragment SectionContentWithImage on cms_ComponentGetInvolvedSectionContentWithImage {
    section_title
    title
    text
    order
    link {
      ...Link
    }
    media {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    media_mobile {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FIXED)
        }
      }
    }
  }
`;
